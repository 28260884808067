import { waitAuth } from "~/utils/middleware";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth();

    if (__subscription().getSubscriptions.length > 0) {
        log("❌ Has no any subscription");
        return navigateTo("/");
    }

    log("✅ Has subscription");
});
